import React from "react";
import EletiveVideo from "../../../../assets/Eletive.mp4";
const EletiveSection3 = (props) => {
    return(
        <section className="section-3">
            <div className="container">
                <div className="title-block">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-20">
                            <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <div className="image-block">
                    <img
                        src={props.image11x?.sourceUrl}
                        srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                        width={props.image11x?.width}
                        alt={props.image11x?.altText}
                    />
                </div>
                <div className="image-block">
                    <video src={EletiveVideo} muted autoPlay loop style={{width: "100%", display: "block"}}></video>
                </div>
                <div className="image-block">
                    <img
                        src={props.image21x?.sourceUrl}
                        srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                        width={props.image21x?.width}
                        alt={props.image21x?.altText}
                    />
                </div>  
            </div>
        </section>
    )
}

export default EletiveSection3;